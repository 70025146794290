<template>
  <div>
    <PageHeaderDisplay
      title="Admin "
      sub-title="Manage areas of Etainabl."
      category="Admin"
      category-logo="fa-user-astronaut"
      :category-route="{ name: $route.name }"
    />
    <TabView route-name="admin-view" :tabs="tabs" :exact="false">
      <router-view />
    </TabView>
  </div>
</template>
<script>
import PageHeaderDisplay from '@/components/PageHeaderDisplay';
import TabView from '@/components/TabView';

export default {
  name: 'AdminView',
  components: {
    PageHeaderDisplay,
    TabView
  },
  data() {
    return {
      searchTerm: ''
    };
  },
  computed: {
    tabs() {
      return [
        {
          name: 'dashboard',
          route: 'admin-dashboard',
          icon: 'fa-tachometer',
          exact: true
        },
        {
          name: 'Tools',
          route: 'admin-tools',
          icon: 'fa-screwdriver-wrench'
        },
        {
          name: 'settings',
          route: 'admin-settings',
          icon: 'fa-sliders-h'
        },
        {
          name: 'log',
          route: 'admin-log',
          icon: 'fa fa-fw text-gray fa-clipboard'
        },
        {
          name: 'emails',
          route: 'admin-emails',
          icon: 'fa fa-fw text-gray fa-envelopes'
        },
        {
          name: 'Gridfetch API',
          route: 'admin-gridfetch',
          icon: 'fa fa-fw text-gray fa-meter-bolt'
        }
      ];
    }
  }
};
</script>
